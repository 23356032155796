import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

export const PrivacyPageTemplate = ({ title, content, contentComponent }) => {
//   const PageContent = contentComponent || Content
const PageContent = Content

  return (
<section style={{padding: '3rem 1.5rem'}}>
    <Container maxWidth='md'>
        <Grid container alignContent='center' alignItems='center'>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="h3" component='h3' style={{paddingBottom:'32px'}}>
                    {title}
                </Typography>
                <PageContent className="content" content={content} />
            </Grid>
        </Grid>
    </Container>
</section>
  )
}



PrivacyPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const PrivacyPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <PrivacyPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.rawMarkdownBody}
      />
    </Layout>
  )
}

PrivacyPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PrivacyPage

export const PrivacyPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      rawMarkdownBody
      frontmatter {
        title
      }
    }
  }
`
